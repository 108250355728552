$(document).ready(function() {
	initMaps();
});

function initMaps() {
	
	if (typeof google === 'undefined') return;
	
var customMapStyle = [{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}];
	
	
	
	google.maps.Marker.prototype.getCustomData = function(key) {
        if(typeof(this.custom_data) === 'undefined') {
            this.custom_data = {};
        }
        if(typeof(key) !== 'undefined') {
            return this.custom_data[key];
        }
        else {
            return this.custom_data;
        }
    }
	
	$('[data-map]').each(function () {
		var map, centerLat, centerLng, zoom, marker, markerLat, markerLng;
		
		if (typeof $(this).data('map-center-coords') != 'undefined') {
			centerLat = parseFloat( $(this).data('map-center-coords').split(',')[0] );
			centerLng = parseFloat( $(this).data('map-center-coords').split(',')[1] );
		} else {
			centerLat = 0;
			centerLng = 0;
		}
		
		
		if (typeof customMapStyle !== 'undefined') {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: 12,
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: customMapStyle
			});
		} else {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: 12,
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false
			});
		}
		

		/*if (typeof $(this).data('map-marker-coords') != 'undefined') {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}
			
			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});
			
			 if (typeof $(this).data('map-marker-url') != 'undefined') {
				marker.setIcon($(this).data('map-marker-url'));
			} 
		}
		
		/* $(this).siblings('[data-map-marker]').each(function () {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}
			
			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});
			
			if (typeof $(this).data('map-marker-url') != 'undefined') {
				marker.setIcon($(this).data('map-marker-url'));
			} 
		}); */
		
	


    var marker ;
    var markers = new Array();
     var my_bubbles = [];
      

    for (var j = 0; j < locations.length; j++) {  
      console.log(j)
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(locations[j][1], locations[j][2]),
        map: map,
        //icon: 'https://www.dalmahoyhotelandcountryclub.co.uk/media/2594/map-marker.png',
        icon: locations[j][5],
        numberwang: j
      });

      var markernumber = j
      
      
      // Also create the info bubble
		my_bubbles[j] = new InfoBubble({
			map: map,
			position: new google.maps.LatLng(locations[j][1],locations[j][2]),
			shadowStyle: 1,
			padding: 10,
			backgroundColor: 'rgb(255, 255, 255)',
			borderRadius: 0,
			borderWidth: 1,
			borderColor: '#cd2636',
			arrowSize: 10,
			disableAutoPan: false,
			hideCloseButton: 0,
			arrowStyle: 0,
			arrowSize: 0,
			arrowPosition: 50,
			backgroundClassName: 'infobubble',
			minWidth: 160,
			minHeight: 70,
			maxWidth: 370,
			maxHeight: 170,
			disableAnimation: true,
			disableAutoPan: true,
			closeSrc: 'https://www.dalmahoyhotelandcountryclub.co.uk/img/map-close.png'
		});

		var html = deentitize(locations[j][4]);
		my_bubbles[j].setContent(html);
		
		
	function deentitize(str) {
		str = str.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
		str = str.replace(/&#39;/g, "'").replace(/&quot;/g, '"');
		return str;
	}

      
      
    // google.maps.event.addListener(marker, 'mouseover', function() {
	     
	 marker.addListener('mouseover', function() {

		for (bubble in my_bubbles) {
			my_bubbles[bubble].close();
		}
		console.log(this['numberwang'])
		console.log (this)
		my_bubbles[this['numberwang']].open(map, markers[this['numberwang']]);
		$('.infobubble').parent().css( "border-color", "#20596a" );
	});	
	
	
	

	markers.push(marker);

    }
    
    console.log (markers)

	
	console.log (my_bubbles)
		
		
	// Override the built in google maps POI infowindow setup
	// method to control the inner html ourselves
	var set = google.maps.InfoWindow.prototype.set;
	google.maps.InfoWindow.prototype.set = function (key, val) {
		var self = this;
		if (key === "map") {
			if ($(this.content).find('.custom-close-button').length==0)
				{
					var close_button = $("<a class='custom-close-button' href='#'></a>");
					$(this.content).find(".transit-container").append(close_button);
					close_button.click(function(e) { e.preventDefault();self.close(); });
				}
		}
		set.apply(this, arguments);
	}


    
		
		
		if (typeof $(this).data('map-directions') != 'undefined') {
			var directionsService  = new google.maps.DirectionsService();
			var directionsRenderer = new google.maps.DirectionsRenderer();
			directionsRenderer.setMap(map);
			directionsRenderer.setPanel($('#map-directions-data').get(0));
			
			var directionsTo = $(this).data('map-directions-destination');
			
			$('#frmPostcode').on('submit', function() {
				var directionsFrom = jQuery.trim(jQuery('#tbPostcode').val());
				var directionsRequest = {
					origin: directionsFrom,
					destination: directionsTo,
					travelMode: google.maps.TravelMode.DRIVING,
					unitSystem: google.maps.UnitSystem.IMPERIAL
				};
				
				directionsService.route(directionsRequest, function(result, status){        
			    	if (status == google.maps.DirectionsStatus.OK) {
						marker.setMap(null);
						directionsRenderer.setDirections(result);
			    	} else {
				    	alert('Location "' + directionsFrom + '" not found.');
			    	}
				});
				
				return false;
			});
		}
	});
	
	

}

