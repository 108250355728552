$(document).foundation();

var footerHeight = 0;

$(document).ready(function () {

  initHeader();
  initPromoCarousel();
  initOtherRooms();
  initNoSlider();
  initRestack();
  // initTableBookingModal();

  function initNoSlider() {
      if ( ($('.main-slider').length < 1) && $('.main-video').length < 1 ) {
        $('.content-wrapper').css('padding-top', '180px');
      }
  }

  // function initTableBookingModal () {
  //
  //   var btns = $('a[href="#BookTable"], .booking-modal__close')
  //   var modal = $('.booking-modal')
  //
  //   btns.on('click', function () {
  //       modal.toggleClass('show')
  //       $('body').toggleClass('modal-open')
  //   })
  //
  //
  //
  // }

  function initHeader() {
    headerFont();
    $(window).on('scroll', headerFont);


    $('.burger-nav-1 > ul > li > a').on('click', function () {
      if ( $(this).next('.sub-menu').length > 0 ) { // only trigger if there are sub pages to show
        if ( $(this).parent().hasClass('open') ) {
          // second click of main item allows clicking directly through
          return true;
        } else {
          // close any other open items
          $('.burger-nav-1 > ul > li.open').removeClass('open').find('.sub-menu').slideUp();

          // and open the new one
          $(this).parent().addClass('open');
          $(this).next().slideDown();
        }
        return false; // prevent navigating
      }
    });
  }


  function headerFont() {
    if ( window.pageYOffset > 50 ) {
      $('.header__cta').removeClass('transparent');
    } else {
      $('.header__cta').addClass('transparent');
    }
  }


    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });


    $('.tour a').fancybox({type: 'iframe'});

    $('.wedding-popup').fancybox({type: 'iframe', maxHeight: '350px'});

    $('.gallery-item[href*="vimeo.com"], .gallery-item[href*="matterport.com"]').fancybox({
        wrapCSS: 'fancybox-gallery',
        type: 'iframe',
        helpers: {
            overlay: {
                locked: true
            }
        }
    });

    // Button removey hack
    if (window.location.href.indexOf('things-to-do-new-2') > -1) {
        $('.offer-description, .offer-cta').remove();
    }

    $('.gallery-slider').slick({
        fade: true,
        dots: true
    });

    fixHeader();
    $(document).on('scroll', _.throttle(fixHeader, 100));

    function fixHeader() {
        // Swap the logo for one without the crest @ 42
        // and shrink the header
        if ($(window).scrollTop() >= 42) {
            $('body').addClass('mobile-logo-scrolled');
        } else {
            $('body').removeClass('mobile-logo-scrolled');
        }
    }

    calcFooter();
    //$(document).on('resize', _.throttle(calcFooter, 100));
    //$(document).on('resize', _.throttle(fixFooter, 100));
    //$(document).on('scroll', _.throttle(fixFooter, 100));

    function calcFooter() {
        footerHeight = $('#main-footer').outerHeight();
    }

    function fixFooter() {
        calcFooter();

        //console.log('Scroll: ', $(window).scrollTop(), ' Window Height - Footer Height = ',  (window.innerHeight- footerHeight) );

        if ($(window).scrollTop() >= $('body').outerHeight() - footerHeight) {
            $('body').addClass('static-footer');
        } else {
            $('body').removeClass('static-footer');
        }
    }

    $('#burger-open, #burger-close').on('click', function () {
        $('#burger-menu').toggleClass('open');
        return false;
    });

    if (!Foundation.MediaQuery.atLeast('medium')) {
        $('.burger-nav-1 > ul > li.has-sub-menu > a').on('click', function (e) {
            $(this).parent().toggleClass('active');

			//console.log(e);

            e.preventDefault();
            return false;
        });
    }

    $('.main-slider').slick({
        fade: true,
        speed: 750,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false
    });

    $('.main-video__scroll').on('click', function () {
        $('html, body').animate({
            scrollTop: $('.content-wrapper')
            .first()
            .offset().top - 75
        },
        500
        );
    });

    //home video volume button
    // $('.main-video video').on('play pause ended timeupdate volumechange', function () {
    //     var video = $('.main-video video');
    //     var mute = video.prop('muted') || video.get(0).muted;

    //     if (mute) {
    //         document.getElementById('volumeSwitch').classList.remove('on');
    //         document.getElementById('volumeSwitch').classList.add('off');
    //         document.getElementById('volumeOff').style.visibility = 'visible';
    //         document.getElementById('volumeOn').style.visibility = 'hidden';
    //         document.getElementById("volumeSwitch").checked = true;
    //     } else {
    //         document.getElementById('volumeSwitch').classList.remove('off');
    //         document.getElementById('volumeSwitch').classList.add('on');
    //         document.getElementById('volumeOff').style.visibility = 'hidden';
    //         document.getElementById('volumeOn').style.visibility = 'visible';
    //         document.getElementById("volumeSwitch").checked = false;
    //     }
    // });

    // if (document.getElementById('volumeSwitch') || null) {
    //     document.getElementById('volumeSwitch').addEventListener('change', function () {
    //         var video = $('.main-video video').get(0);

    //         if (this.checked) {
    //             video.muted = true;
    //         } else {
    //             video.muted = false;
    //         }
    //     });
    // }


    $('.main-video__volume input[type="checkbox"]').on('change', function () {

        var iframe = $('#iframe-video');
        var player = new Vimeo.Player(iframe);

        player.getVolume().then(function(volume) {
            if ( volume == 0  ) {
                player.setVolume(0.66);
                $('#volumeSwitch').toggleClass('on');
                $('#volumeSwitch').toggleClass('off');
                $('#volumeOff').css('visibility', 'hidden');
                $('#volumeOn').css('visibility', 'visible');
                $("#volumeSwitch").checked = true;
            } else {
                player.setVolume(0);
                $('#volumeSwitch').toggleClass('on');
                $('#volumeSwitch').toggleClass('off');
                $('#volumeOff').css('visibility', 'visible');
                $('#volumeOn').css('visibility', 'hidden');
                $("#volumeSwitch").checked = false;
            }
        })
    })

    //initMaps();

    /*** Filterable Offers ***/
    var $_filterButtons = $('#offer-filters button');
    var $_filterItems = $('.offer-item');

    $('#offer-filters button').on('click', function () {
        $(this).toggleClass('on');

        // Clear the "All" button
        if (!$(this).hasClass('all')) {
            $('#offer-filters button.all').removeClass('on');
        } else {
            // All button has been clicked, so show all buttons and return
            $('#offer-filters button').removeClass('on');
            $('#offer-filters button.all').addClass('on');
            $_filterItems.fadeIn();
            return;
        }

        var currentCats = '';

        // Loop through the "on" buttons and build a class selector string of the active
        $_filterButtons.each(function () {
            if ($(this).hasClass('on')) {
                currentCats += '.' + $(this).data('category') + ', ';
            }
        });
        currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks

        // No categories selected so show them all
        if (currentCats == '') {
            $_filterItems.fadeIn();
            return;
        }

        // Hide or show the elements that match the class
        $_filterItems.each(function () {
            if ($(this).is(currentCats)) {
                $(this).fadeIn();
            } else {
                $(this).fadeOut();
            }
        });
    });

    // Pre-filter the offers
    // Get the url parameters
    var urlParams = {};
    var e,
        a = /\+/g, // Regex for replacing addition symbol with a space
        r = /([^&=]+)=?([^&]*)/g,
        d = function (s) {
            return decodeURIComponent(s.replace(a, ' '));
        },
        q = window.location.search.substring(1);

        while ((e = r.exec(q))) urlParams[d(e[1])] = d(e[2]);

        if (urlParams['category']) {
            $('#offer-filters button[data-category="' + urlParams['category'] + '"]').trigger('click');
        }

        /*** Booking Widget ***/

        $('.booking-bar__close').on('click', function () {
          $('#booking-bar-wrapper').fadeOut();
        });

        $('#mobile-booking').on('click', function () {
          $('#booking-bar-wrapper').fadeIn();
          return false;
        });

        $('#booking-bar-wrapper select').selectric({
          disableOnMobile: false,
          nativeOnMobile: false
        });

        $('.selectric-scroll li').on('click', function() {
          $(this).siblings().addClass('highlighted')
          $(this).addClass('highlighted')
        })

        $('#booking-type-select').on('change', function () {
            $('.booking-bar-type').hide();

            // if ($(this).val() === 'booking-type-table') {
            //     $('#booking-bar-inner').css('height', '710px')
            //     $('#booking-type-select').css('width', '145px')
            //     $('#booking-type-select').css('z-index', 999999)
                //$('#booking-bar-choose label').addClass('hide-for-current-selection');
            // } else {
                $('#booking-bar-inner').css('height', '75px')
                $('#booking-type-select').css('width', 'unset')
                $('#booking-type-select').css('z-index', 'unset')
                //$('#booking-bar-choose label').removeClass('hide-for-current-selection');
            // }

            // Make the dropdown wider for
            if ($(this).val() === 'booking-type-meetings-events') {
              $('#booking-bar-choose').addClass('wide-option');
            } else {
              $('#booking-bar-choose').removeClass('wide-option');
            }

            switch ($(this).val()) {
                case 'booking-type-room':
                $('#booking-bar-room').css('display', 'inline-block');
                break;
                case 'booking-type-golf':
                $('#booking-bar-golf').css('display', 'inline-block');
                break;
                case 'booking-type-table':
                $('#booking-bar-table').css('display', 'inline-block');
                break;
                case 'booking-type-wedding':
                $('#booking-bar-wedding').css('display', 'inline-block');
                break;
                case 'booking-type-meetings-events':
                $('#booking-bar-meetings-events').css('display', 'inline-block');
                break;
                default:
                $('#booking-bar-room').css('display', 'inline-block');
                break;
            }
        });

        if (window.location.href.indexOf('golf') > -1) {
            $('#booking-type-select').val('booking-type-golf');
        } else if (window.location.href.indexOf('food') > -1) {
            $('#booking-type-select').val('booking-type-table');
        } else if (window.location.href.indexOf('weddings') > -1) {
            $('#booking-type-select').val('booking-type-wedding');
        } else if (window.location.href.indexOf('meetings') > -1) {
            $('#booking-type-select').val('booking-type-meetings-events');
        }
        $('.booking-bar-type').hide();
        $('#booking-type-select').trigger('change').selectric('refresh');


        /*switch ($('#booking-type-select').val()) {
            case 'booking-type-room':
            $('#booking-bar-room').css('display', 'inline-block');
            $('#mobile-booking').attr('href', 'https://ag.avvio.com/convert/site/Dalmahoy Hotel and Country Club/en/');
            break;
            case 'booking-type-golf':
            $('#booking-bar-golf').css('display', 'inline-block');
            $('#mobile-booking').attr('href', 'https://www.dalmahoyhotelandcountryclub.co.uk/golf/book-a-tee-time/');
            break;
            case 'booking-type-table':
            $('#booking-bar-table').css('display', 'inline-block');
            break;
            case 'booking-type-wedding':
            $('#booking-bar-wedding').css('display', 'inline-block');
            break;
            case 'booking-type-meetings-events':
            $('#booking-bar-meetings-events').css('display', 'inline-block');
            break;
            default:
            $('#booking-bar-room').css('display', 'inline-block');
            $('#mobile-booking').attr('href', 'https://ag.avvio.com/convert/site/Dalmahoy Hotel and Country Club/en/');
            break;
        } */

        $('.datepicker').fdatepicker({
            format: 'dd-mm-yyyy',
            onRender: function (date) {
                var now = new Date(new Date().setDate(new Date().getDate() - 1));
                if (date >= now) {
                    return '';
                } else {
                    return 'disabled';
                }
            //return date.valueOf() < now.valueOf() ? 'disabled' : '';
        }
    });

        $('#c5QuickBook').on('submit', function () {
            var normalDate = $('#checkin').val();
            if (normalDate == '') {
            // Preset to today if no date is picked
            var today = new Date();
            normalDate = today.getDate() + '-' + pad(today.getMonth() * 1 + 1, 2) + '-' + today.getFullYear();
            $('#checkin').val(normalDate);
        }
        // Swap the date format into US style for booking engine
        var normalDateArr = normalDate.split('-');
        $('#checkin').val(normalDateArr[2] + '-' + normalDateArr[1] + '-' + normalDateArr[0]);
    });

        function pad(n, width, z) {
            z = z || '0';
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }

        // $('a[href="#bookatable"]').lbuiDirect({
        //     connectionid: 'UK-RES-PENTLANDRESTAURANT_133157:67593',
        //     gaAccountNumber : "UA-79954114-1",
        //     style: {
        //         baseColor: '5e5544'
        //     },
        //     modalWindow: {
        //         enabled: true
        //     }
        // });

        // $('a[href="#bookafternoontea"]').lbuiDirect({
        //     connectionid: 'UK-RES-PENTLANDRESTAURANT_133157:67593',
        //     gaAccountNumber : "UA-79954114-1",
        //     style: {
        //         baseColor: '5e5544'
        //     },
        //     modalWindow: {
        //         enabled: true
        //     },
        //     preselect: {
        //         sessionid: 'AFTERNOONTEA'
        //     }
        // });

        /** Videos **/
        $('.video-preview a').fancybox({
            wrapCSS: 'fancybox-video',
            parent: $('body'),
            afterShow: function () {
                var video = $(this.content).find('video')[0];
                video.play();
            },
            beforeClose: function () {
                var video = $(this.content).find('video')[0];
                video.pause();
                video.currentTime = 0;
            }
        });

        $('.validate-this').validate();
    });


// newsletter pop-up 08-18 ticket 5931
// $.fancybox("#hidden");



$(document).ready(function () {


    var visited = $.cookie('visited');

    if (visited !== 'yes') {

        // set cookie
        $.cookie('visited', 'yes');

        // fancybox code

        $(".fancybox").fancybox({
            maxHeight: 1000,
            width: '70%',
            maxWidth: 600,
            fitToView: false,
            autoSize: false,
            scrolling: 'no',
            height: 'auto',
            helpers: { overlay: { closeClick: false } },
            padding: 0,
            afterClose: function () {
                $(".newsletter-pop").removeAttr('id');
            },
            afterLoad: function () {
                $(".fancybox-overlay").addClass("newsletter-pop");
            }
        });

        setTimeout(function () {
            $(".fancybox").trigger('click');
        }, 5000);

        // end fancybox code

    }
});





// initAnimateInView();
$(document).ready(function () {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
  scaleVideo();
  $(window).on('resize', scaleVideo);
});


function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		//console.log($(this));
		if ( isElementInViewport($(this)) ) {
			$(this).addClass( $(this).data('animate-in-view') );
		}
	});
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport (el) {
    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
       rect.top <=  $(window).scrollTop() + window.innerHeight
       );
}


function scaleVideo() {
    var viewportWidth = $(window).innerWidth();
    var viewportHeight = $(window).innerHeight();

    var $videoElement;
    if ( $('#iframe-video').length > 0 ) {
        $videoElement = $('#iframe-video');
    } else if ( $('.full-page-video video').length > 0 ) {
        $videoElement = $('.full-page-video video');
    } else {
        return;
    }

    var videoWidth = $videoElement.attr('width');
    var videoHeight = $videoElement.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = videoHeight / videoWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (videoWidth / videoHeight) * viewportHeight
    var newHeight = (videoHeight / videoWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $videoElement.css('height', viewportHeight);
        $videoElement.css('width', newWidth);
        $videoElement.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $videoElement.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $videoElement.css('width', viewportWidth);
        $videoElement.css('height', newHeight);
        $videoElement.css('left', '0');
        $videoElement.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $videoElement.css('height', viewportHeight);
        $videoElement.css('width', viewportWidth);
        $videoElement.css('left', '0');
        $videoElement.css('top', '0');
    }
}



function initPromoCarousel() {
  if ( $('.promo-carousel__inner').length > 0 ) {
      if ( $('.promo-carousel__item').length > 3 ) {
        $('.promo-carousel').addClass('with-carousel');

        $('.promo-carousel__inner').slick({
          slidesToShow: 3,
          centerMode: true,
          centerPadding: 0,
          arrows: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                centerMode: false
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                centerMode: false
              }
            }
          ]
        });
      } else {

        $('.promo-carousel__inner').slick({
          slidesToShow: 3,
          centerMode: true,
          centerPadding: 0,
          arrows: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                centerMode: false
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                centerMode: false
              }
            }
          ]
        });
      }



    alignPromoCarouselArrows();
    $(window).on('resize', alignPromoCarouselArrows);
  }
}

function alignPromoCarouselArrows() {
  if ( window.innerWidth > 1024 ) {
    var imgHeight = $('.promo-carousel .slick-current .promo-carousel__image').outerHeight();
    $('.promo-carousel .slick-arrow').css('top', imgHeight / 2 + 'px');
  } else {
    $('.promo-carousel .slick-arrow').css('top', 'initial');
  }
}

setTimeout(function () {
  $(window).trigger('resize');// landing page hack?
}, 1000);

function initOtherRooms() {
    if ( $('.other-rooms__inner').length > 0 ) {
      $('.other-rooms__inner').slick({
        slidesToShow: 3,
        centerMode: true,
        centerPadding: 0,
        arrows: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              centerMode: false
            }
          }
        ]
      });

      alignPromoCarouselArrows();
      $(window).on('resize', alignPromoCarouselArrows);
    }
  }

  function alignPromoCarouselArrows() {
    var imgHeight = $('.other-rooms .slick-current .other-rooms__image').outerHeight();
    $('.other-rooms .slick-arrow').css('top', imgHeight / 2 + 'px');
  }


  function initRestack() {


    if (window.location.href.indexOf("golf")  > -1 && window.innerWidth > 760) {
  setTimeout(function() {
    (function()
    {
      if( window.localStorage )
      {
        if( !localStorage.getItem('firstLoad') )
        {
          localStorage['firstLoad'] = true;
          window.location.reload();
          console.log('loaded')
        }
        else
          localStorage.removeItem('firstLoad');
      }
    })()
  }, 3000)
    }

}
